const NodeRSA = require('node-rsa');

const generateKeys = () => {
    try {
        const key = new NodeRSA();
        key.generateKeyPair(2048, 65537);
        const publicKey = key.exportKey('public');
        const privateKey = key.exportKey('private');
        return { publicKey, privateKey, success: true };
    } catch (err) {
        return {
            success: false,
        };
    }
};

let keys = generateKeys();

onmessage = () => {
    self.postMessage(keys);
};
